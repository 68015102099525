.spacer-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: white;
    padding: 0;
}

.spacer-form-container .spacer-container {
    display: none;
}

.contact-form-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

h2 {
    text-align: center;
}

@media only screen and (min-width: 992px) {
    .contact-container {
        align-items: center;
    }

    .form {
        height: auto;
    }

    .spacer-form-container {
        width: 500px;
        height: auto;
        align-items: center;
        border-radius: 25px;

        -webkit-box-shadow: 1px 4px 5px 0 rgba(0,0,0,0.36);
        box-shadow: 1px 4px 5px 0 rgba(0,0,0,0.36);

    }

    .spacer-form-container .spacer-container {
        display: unset;
    }

    .spacer-form-container .spacer-container:first-child {
        margin-left: 1rem;
    }

    .spacer-form-container .spacer-container:last-child {
        margin-right: 1rem;
    }

    .contact-form-container {
        margin: 0 5%;
        padding: 0.938rem;
    }

    .submit-btn:hover {
        background-color: var(--primary-color);
        color: var(--secundary-color);
        border: 2px solid var(--primary-color);
        cursor: pointer;
    }
}