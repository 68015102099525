#galerij-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.galerij-info {
    width: 100%;
    max-height: fit-content;
    margin: 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.galerij-info p {
    width: 90%;
    height: auto;
    text-align: justify;
}

#carousel {
    display: none;
}

#foto-overzicht {
    width: 100%;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-wrap: wrap;
}

.foto-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0.5rem;
}

.foto-container img {
    width: 100%;
    height: auto;
}

@media only screen and (min-width: 992px) {

    .galerij-info {
        max-width: 992px;
        align-items: flex-start;
    }

    .galerij-info p {
        width: 100%;
    }

    #foto-overzicht {
        width: calc(6 * 200px + 6rem);
    }

    #foto-overzicht-flat {
        width: 100%;
        height: 200px;
        display: block;
        justify-content: center;
        align-items: center;
        overflow: scroll;
        white-space: nowrap;
    }

    .foto-container {
        height: 150px;
        max-width: 150px;
        display: inline-block;
    }

    .foto-container:focus {
        height: 150px;
        max-width: 150px;
        display: inline-block;
        border: 1px solid var(--secundary-color);
    }

    .foto-container img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    #carousel {
        width: 100%;
        height: calc(100vh - 200px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #carousel img {
        height: 100%;
    }

    #carousel-btn-next, #carousel-btn-prev, #carousel-btn-close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: var(--secundary-text);
        background-color: var(--secundary-color);
        z-index: 20;
        -webkit-user-select: none;
        user-select: none;
    }

    #carousel-btn-next:hover, #carousel-btn-prev:hover, #carousel-btn-close:hover, .foto-container:hover {
        cursor: pointer;
    }

    #carousel-btn-next, #carousel-btn-prev {
        width: 100px;
        height: 100px;
        top: calc((100vh - 300px) / 2);
        font-size: xx-large;
    }

    #carousel-btn-close {
        width: 30px;
        height: 30px;
        top: 1rem;
        right: 1rem;
    }

    #carousel-btn-prev {
        left: 50px;
    }

    #carousel-btn-next {
        right: 50px;
    }

}