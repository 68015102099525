.btn {
    border-radius: 1.5625rem;
    padding: 0.625rem 1rem;
    background-color: var(--secundary-color);
    border: 2px solid var(--primary-color);
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 25px;
}

.btn:hover {
    background-color: var(--primary-color);
    color: var(--secundary-color);
    border: 2px solid var(--primary-color);
    cursor: pointer;
}