.input-field {
    box-sizing: content-box;
    background-color: var(--primary-color);
    color: #636363;
    margin: 10px 0;
    width: 95%;
    height: 1.75rem;
    padding-left: .5rem;
    border-radius: 10px;
    outline: none;
    -webkit-box-shadow: inset #ddd 1px 1px 2px;
    -moz-box-shadow: inset #ddd 1px 1px 2px;
    box-shadow: inset #ddd 2px 2px 2px;
    border: none;
}

.input-field:focus {
    background-color: white;
    color: var(--secundary-color);
    outline: none;
}

.input-field::placeholder {
    color: #636363;
}

.input-field::placeholder:active {
    color: black;
}

textarea {
    min-height: 6.25rem;
    border: none;
}


@media only screen and (min-width: 992px) {
    .input-field {
        width: 100%;
    }
}