.fotovdmaand-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fotovdmaand-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}

.fotovdmaand-wrapper img {
    width: 30%;
}

.fotovdmaand-info {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    border: 1px solid black;
}

.fotovdmaand-info p {
    cursor: pointer;
}