.eventA-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.eventA-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid black;
}

.eventA-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventA-overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

input, select, textarea {
    margin: 2px;
    width: 80%;
}

.foto-container-createEvent {
    position: relative;
    height: 150px;
    max-width: 150px;
    display: inline-block;
    border: 1px solid var(--secundary-color);
}

.foto-container-createEvent img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.buttons {
    position: absolute;
    bottom: 0;
    left: 0;
}

.buttons button {
    cursor: pointer;
}

.delete-button {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.foto {
    cursor: default;
}

