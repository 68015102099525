.image-overlay {
    position: absolute;
    height: 100%;
    width:50%;
    background: transparent;
    pointer-events: none;
}

.preview, .preview img {
    width: 100%;
    border-radius: 0;

}

.preview img {
    height: auto;
}

.preview {
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

hr {
    width: auto;
    height: 0;
    margin: 25px;
    align-self: center;

}

.preview-container a .info {
    text-overflow: ellipsis;
    border: 1px solid black;
}

.preview-container h2 {
    width: 90%;
    margin: 0;
    padding: 0;
    text-align: left;
}


.preview-container a:link,
.preview-container a:visited,
.preview-container a:hover,
.preview-container a:active {
    color: var(--accent-text)
}

.info, .info h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.info {
    text-align: justify;
}

.info p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
}

@media only screen and (min-width: 992px) {
    .preview-container {
        display: flex;
        margin: 100px 0;
        width: 80%;
        height: 500px;
    }

    .preview-container:nth-of-type(odd) {
        flex-direction: row-reverse;
    }

    .preview-container:nth-of-type(even) {
        flex-direction: row;
    }

    .preview {
        height: 100%;
        display: flex;
        overflow: hidden;
        align-self: center;
    }

    .preview img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 100px;
    }

    hr {
        width: 5%;
        height: 0;
        align-self: center;
    }

    .info h2 {
        margin: 0;
        padding: 0;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-self: center;
        width: 100%;
        height: 50%;
    }

    .info p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 80%;
        text-overflow: ellipsis;
    }


}