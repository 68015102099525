footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--secundary-color);
    color: var(--primary-color)
}

.adres, .social, .meet {
    width: 275px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.adres, .meet {
    font-size: larger;
    text-align: center;
}

.meet nav {
    text-decoration: none;
}

.adres {
    margin: 50px 0;
}

.social {
    flex-direction: column;
}

.social .logos img {
    width: 50px;
    height: 50px;
    margin: 0 5px;
}

.meet {
    flex-direction: column;
    cursor: pointer;
}

.meet span {
    font-size: larger;
    font-weight: bold;
    margin-top: 14px;
}

@media only screen and (min-width: 992px) {
    footer {
        height: 200px;
        flex-direction: row;
    }
    
    .adres, .social, .meet {
        margin: 0;
    } 
    
}