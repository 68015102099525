#nav-container {
    display: flex;
}

nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    list-style-type: none;
    top: 100px;
    right: 1px;
}

nav a, nav .title {
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secundary-color);
    border-top: 1px solid var(--primary-color);
    text-decoration: none;
    color: var(--secundary-text);
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}

nav .title {
    border-bottom: 1px solid var(--primary-color);
}

nav a:hover {
    background-color: var(--primary-color);
    color: var(--secundary-color);
    font-weight: bold;
}

.dropdown-open {
    width: 200px;
}



.title:hover {
    cursor: pointer;
}

.dropdown-open a {
    width: 100%;
    background-color: var(--primary-color);
    color: var(--accent-text);
    border-bottom: 1px solid var(--accent-text);
}

.dropdown-open a:hover {
    background-color: var(--secundary-color);
    color: var(--secundary-text);
    border-bottom: 1px solid var(--accent-text);
}

.dropdown-open {
    width: 198px;
    height: auto;
    border: 1px solid var(--secundary-color);
    background-color: var(--primary-color);
    z-index: initial;
}

.dropdown-open a:last-child {
    border: 0;
}

.dropdown-closed {
    display: none;
    height: 0;
}

.open {
    visibility: initial;
}

.closed {
    visibility: hidden;
}

.burger-btn-open, .burger-btn-closed {
    width: 31px;
    height: 31px;
    position: relative;
    right: 25px;
    border: 1px solid white;
}

.burger-btn-open div,
.burger-btn-closed div {
    width: 23px;
    height: 5px;
    background-color: var(--primary-color);
    opacity: 100%;
}

.burger-btn-open {
    justify-content: center;
    transition: justify-content 250ms ease-in-out;
}

.burger-btn-closed div, .burger-btn-open div {
    position: absolute;
    left: 4px;
}

.burger-btn-closed div {
    rotate: 0;
    transition: rotate 100ms ease-in-out;
}

.burger-btn-closed div:nth-child(1) {
    top: 4px;
    transition:
        rotate 100ms ease-in-out,
        top 250ms ease-in-out 100ms;
}

.burger-btn-closed div:nth-child(2) {
    top: 13px;
    opacity: 100%;
    transition: opacity 0ms linear 100ms;
}

.burger-btn-closed div:nth-child(3) {
   bottom: 4px;
   transition:
        rotate 100ms ease-in-out,
        bottom 250ms ease-in-out 100ms;
}

.burger-btn-open div:nth-child(1) {
    top: 13px;
    rotate: 45deg;
    transition: top 250ms ease-in-out, rotate 100ms ease-in-out 250ms;
}

.burger-btn-open div:nth-child(2) {
    top: 13px;
    opacity: 0;
    transition: opacity 0ms linear 250ms;
}

.burger-btn-open div:nth-child(3) {
    bottom: 13px;
    rotate: -45deg;
    transition: bottom 250ms ease-in-out, rotate 100ms ease-in-out 250ms;
}

.burger-btn-open div:first-child, .burger-btn-open div:last-child {
    opacity: 100%;
}

@media only screen and (min-width: 992px) {
    nav {
        flex-direction: row;
        position: unset;
        visibility: visible !important;
    }
    
    nav a {
        width: auto;
        background-color: var(--secundary-color);
        border: none;
    }

    nav a:hover {
        background-color: initial;
        color: var(--secundary-text);
        font-weight:initial;
    }

    nav > *{
        margin-left: 100px;
    }

    nav a:first-child {
        margin: 0;
    }

    nav .title {
        width: auto;
        border: none;

    }

    .dropdown-open{
        width: 200px;
        /*left: ;*/
    }

    .dropdown-open {
        position: absolute;
        width: 200px;
        border: 1px solid var(--secundary-color);
        background-color: var(--primary-color);
        opacity: 0.95;
        margin-left: 60px;
    }

    .dropdown-open a {
        width: 200px;
        margin-left: 0;
    }

    #up {
        top: -410px;
    }

    #down {
        top: 100px;
    }

    .burger-btn-open, .burger-btn-closed {
        width: 31px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 1px solid white;
        visibility: hidden;
    }

}