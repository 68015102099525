#kalender-pagina-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#kalender-container {
    width: 100%;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#kalender-container h2 {
    width: 90%;
    margin-left: 1rem;
}

#kalender-items {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
}

@media only screen and (min-width: 992px) {
    #kalender-pagina-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #kalender-container {
        width: 75%;
    }

    #kalender-container h2 {
        margin-left: 1rem;
    }

    #kalender-items {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 5rem;
    }
}