#info-container {
    width: 100%;
    height: auto;
}

#info-container p {
    text-align: center;
    margin: 0 1rem 1rem 0;
}

@media only screen and (min-width: 768px) {
    #info-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #info-container main {
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0;
    }

    #info-container p {
        text-align: center;
        margin: 0 1rem 1rem 0;
    }

    #info-container h2 {
        font-size: 2rem;
    }
}
