.contact-container {
    width: 100%;
    min-height: calc(100vh - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.form-section, .whereToFind-section {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form_select {
    box-sizing: content-box;
    background-color: var(--primary-color);
    color: #636363;
    margin: 10px 0;
    width: 100%;
    height: 1.75rem;
    padding-left: .5rem;
    border-radius: 10px;
    outline: none;
    -webkit-box-shadow: inset #ddd 1px 1px 2px;
    -moz-box-shadow: inset #ddd 1px 1px 2px;
    box-shadow: inset #ddd 2px 2px 2px;
    border: none;
}

.form_select_multiple {
        box-sizing: content-box;
        background-color: var(--primary-color);
        color: #636363;
        margin: 10px 0;
        width: 100%;
        padding-left: .5rem;
        border-radius: 10px;
        outline: none;
        -webkit-box-shadow: inset #ddd 1px 1px 2px;
        -moz-box-shadow: inset #ddd 1px 1px 2px;
        box-shadow: inset #ddd 2px 2px 2px;
        border: none;
        /* Ensure the select element shows at least 3-4 options */
        height: auto; /* Let the height adjust based on content */
        min-height: 1.5rem; /* Adjust to show at least 3 options (option height can vary) */
        overflow-y: auto; /* Enable scroll if more options are available */

}

.form_select_multiple option {
    padding: 0.25rem; /* Add padding to make options easier to select */
}


.whereToFind-section article {
    text-align: center;
    margin-top: 25px;
}

.whereToFind-section iframe {
    margin-top: 25px;
    display: none;
}

.whereToFind-section h2 {
    margin-top: 25px;
    display: none;
}

.whereToFind-section a {
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 25px;
    color: var(--secundary-text);
    background-color: var(--secundary-color);
    margin: 50px 0;
}



@media only screen and (min-width: 992px) {
    .contact-container {
        flex-direction: row;
        justify-content: space-evenly;
        background-color: unset;
    }

    .form-section, .whereToFind-section {
        width: auto;
    }

    .whereToFind-section iframe {
        display: unset;
    }
}