.event-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
}

.event-top {
    display: flex;
    align-items: center;

}

.event-top-left {
    display: flex;
    width: 5%;
    border: 1px solid black;

}

.event-top-middle-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
}


.event-top-middle-left {
    width: 40%;
    display: flex;
}

.event-top-middle-right {
    display: flex;
    flex-direction: column;
    align-self: end;
}


.event-bottom {
    display: flex;
    justify-content: space-between;
}

.event-bottom p {
    margin: 0 10px 0 10px;
}

.foto-add {
    width: 100%;
    height: 100%;
    border: 1px solid black;
}

.foto-add:hover {
    border: 1px solid red;
    box-shadow: #0f0f0f 0 0 10px 0;
    color: red;
}

.foto-container-eventItem {
    position: relative;
    height: 150px;
    max-width: 150px;
    display: inline-block;
    margin: 1px;
}


.foto-container-eventItem img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}