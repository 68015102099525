.agenda-item {
    width: 100%;
    min-height: 1.5rem;
    padding: 0.5rem 1rem;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.36);
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.36);
    margin: 5px 0;
    border-radius: 5px;
}

.agenda-item:first-child {
    font-weight: bold;
    text-transform: uppercase;
}

/*.agenda-item+.agenda-item {*/
/*    margin-top: 1rem;*/
/*}*/

.agenda-item:nth-of-type(even   ) {
    background-color: lightgrey;
}

.agenda-item:last-child {
    border: none;
}

.soort, .datum, .tijd {
    width: 100px;
}

.bijzonderheden {
    width: 250px;
}

.activiteit {
    width: 640px;
}

@media only screen and (min-width: 992px) {
    .agenda-item {
        min-height: 2.5rem;
        flex-wrap: nowrap;

    }

    .agenda-item:first-child {
        font-weight: bold;
        text-transform: uppercase;
    }

    /*.agenda-item+.agenda-item {*/
    /*    margin-top: 1rem;*/
    /*}*/
    .agenda-item:nth-of-type(even   ) {
        background-color: lightgrey;
    }

    .agenda-item:last-child {
        border: none;
    }

    .soort, .datum, .tijd {
        text-align: left;
        width: 100px;
    }

    .bijzonderheden {
        text-align: center;
        width: 250px;
    }

    .programma {
        text-align: left;
        width: 640px;
    }
}