.calendar-admin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.calendar-items-overview {
    width: 100%
}

.calendar-items-create-edit {
    width: 100%;
}

td {
    text-align: center;
}